import * as React from 'react';

/*
 * hopsital Managers manage their team
 * Note: did minimal renaming from the UserManage component
 */
import { Badge, Button, Col } from 'react-bootstrap';

import ReactTable, {
    Column,
    FinalState,
    RowInfo,
    SortingRule
} from 'react-table';
import {
    bulkUpdateInstallProducts,
    clearSelectedInstallBaseID,
    clearSelectedProductID,
    getProductInfo,
    initInventory,
    moveFacilitiesAPI,
    requestQuote,
    setSearchNewProductsSelectedProduct,
    setSelectedInstallBaseID,
    setSelectedProductID,
    setTableFilter,
    toggleEditInstallModal,
    toggleEditProductModal,
    toggleImportInstallModal,
    toggleSearchNewProductsModal
} from '../../actions/manageInventoryActions';
import { getWorkOrders } from '../../actions/manageWorkOrderActions';
import {
    Ifacility,
    IinstallBase,
    IinstallBasePopulated,
    ImanageInventoryReducer,
    Iproduct,
    IproductInfo,
    IsearchNewProductInstallBatchMode,
    Itile,
    Iuser
} from '../../models';
import {
    emptyTile,
    initialProduct,
    initialProductQueueObject
} from '../../reducers/initialState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { FieldConfig } from 'react-reactive-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { closeAllModals } from '../../actions/commonActions';
import { getFacility } from '../../actions/manageLocationActions';
import { toggleShoppingCartModal } from '../../actions/shoppingCartActions';
import { constants } from '../../constants/constants';
import { measurementPointResultStatusTypesEnum } from '../../models-enums';
import { getIsMobile } from '../../reducers/configReducer';
import { Banner } from '../common/Banner';
import { FormUtil } from '../common/FormUtil';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { WorkOrderExpander } from '../dashboard/WorkOrderExpander';
import { EditWorkOrderModal } from '../manageWorkOrder/EditWorkOrderModal';
import { WorkOrderCloseModal } from '../manageWorkOrder/WorkOrderCloseModal';
import ShoppingCartForm from '../shoppingCart/ShoppingCartForm';
import ShoppingCartModal from '../shoppingCart/ShoppingCartModal';
import EditInstallModal from './EditInstallModal';
import EditProductModal from './EditProductModal';
import ImportInstallModal from './ImportInstallModal';
import { InstallBasesExpanderContainer } from './InstallBasesExpanderContainer';
import InstallContactModal from './InstallContactModal';
import { MPResultHistoryModal } from './MPResultHistoryModal';
import { MPResultListModal } from './MPResultListModal';
import { MPResultListNotesModal } from './MPResultNotesModal';
import SearchNewFacilityModal from './SearchNewFacilityModal';
import SearchNewProductsModal from './SearchNewProductsModal';
import { MPResultHistoryMobileModal } from './mobile/MPResultHistoryMobileModal';
import { MPResultListMobileModal } from './mobile/MPResultListMobileModal';
import { MPResultListNotesMobileModal } from './mobile/MPResultNotesMobileModal';
import ManageInventoryDetails from './mobile/ManageInventoryDetails';
import {
    HIDE_MODAL_EDIT_PRODUCT,
    HIDE_MODAL_SEARCH_NEW_PRODUCTS
} from '../../actions/actionTypes';
interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    t: TFunction;
    loading: boolean;
    manageInventory: ImanageInventoryReducer;
    toggleEditProductModal: typeof toggleEditProductModal;
    toggleEditInstallModal: typeof toggleEditInstallModal;
    toggleShoppingCartModal: typeof toggleShoppingCartModal;
    toggleSearchNewProductsModal: typeof toggleSearchNewProductsModal;
    toggleImportInstallModal: typeof toggleImportInstallModal;
    getProductInfo: typeof getProductInfo;
    getFacility: typeof getFacility;
    toggleSecurityFunctionsModal: () => void;
    initInventory: typeof initInventory;
    closeAllModals: typeof closeAllModals;
    productInfo: IproductInfo;
    user: Iuser;
    cartTotal: number;
    tableData: any;
    setTableFilter: typeof setTableFilter;
    selectedProduct: Iproduct;
    searchNewProductsSelectedProduct: Iproduct;
    requestQuote: typeof requestQuote;
    selectedFacility: Ifacility;
    selectedInstallBase: IinstallBase;
    setSelectedProductID: typeof setSelectedProductID;
    clearSelectedProductID: typeof clearSelectedProductID;
    setSelectedInstallBaseID: typeof setSelectedInstallBaseID;
    clearSelectedInstallBaseID: typeof clearSelectedInstallBaseID;
    setSearchNewProductsSelectedProduct: typeof setSearchNewProductsSelectedProduct;
    canEditInstalls: boolean;
    canRequestQuote: boolean;
    bannerTitle: string;
    className: string;
    showEditWorkOrderModal: boolean;
    toggleEditWorkOrderModal: () => any;
    showWorkOrderCloseModal: boolean;
    mergeProduct: (sprod: string, dproduct: string) => Promise<void>;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    showSearchNewFacilityModal: boolean;
    installIdsToMove: string[];
    filterLength: number;
}

interface IpagingInfo {
    count: number;
    numPages: number;
}

const ManageInventory: React.FC<Iprops> = (props: Iprops) => {
    const { selectedFacility } = props;
    const isMobile = useSelector(getIsMobile);
    const dispatch = useDispatch();
    const buildSearchControls = (): FieldConfig => {
        const { tableFilters } = props.manageInventory;
        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:searchProduct',
                    colWidth: isMobile ? 12 : 3,
                    type: 'text',
                    placeholder: isMobile
                        ? 'common:searchProductAssetPlaceholder'
                        : 'searchPlaceholder',
                    defaultValue: tableFilters.search
                }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:mainCategory',
                    options: props.productInfo.mainCategoryOptions,
                    colWidth: isMobile ? 12 : 3,
                    type: 'select',
                    placeholder: isMobile
                        ? 'common:searchCategoryPlaceholder'
                        : 'mainCategoryPlaceholder',
                    defaultValue: tableFilters.mainCategory,
                    isClearable: true
                }
            },
            status: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:status',
                    options: FormUtil.convertEnumToOptions(
                        measurementPointResultStatusTypesEnum
                    ),
                    colWidth: isMobile ? 12 : 3,
                    type: 'select',
                    placeholder: isMobile
                        ? 'common:searchStatusPlaceholder'
                        : 'statusPlaceholder',
                    defaultValue: tableFilters.status,
                    isClearable: true,
                    shouldTranslate: true
                }
            },
            hideVirtualToggle: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'Hide Virtual',
                    colWidth: isMobile ? 12 : 1,
                    defaultValue: tableFilters.hideVirtualToggle,
                    innerStyle: {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column-reverse',
                        gap: '4px'
                    }
                }
            }
        };

        return {
            controls: { ...mainSearchControls }
        } as FieldConfig;
    };

    const [state, setState] = useState<Istate>({
        selectedRow: {},
        currentTile: emptyTile,
        searchFieldConfig: buildSearchControls(),
        showSearchNewFacilityModal: false,
        installIdsToMove: [],
        filterLength: 0
    });

    const [pagingInfo, setPagingInfo] = useState<IpagingInfo>({
        count: 0,
        numPages: 0
    });

    let setTableFilterTimeout: any;

    const { t, manageInventory } = props;
    const isManageAssetView = props.match.path === '/inventory';
    const selectedProductForEditInstall = manageInventory.showSearchNewProductsModal
        ? props.searchNewProductsSelectedProduct
        : props.selectedProduct;
    const colorButton =
        constants.colors[
            `${state.currentTile.color}Button` as keyof typeof constants.colors
        ];

    const getQuantityColumn = (): Column<any> => {
        if (isManageAssetView) {
            return {
                Header: 'qty',
                id: 'quantity',
                width: 80,
                accessor: ({ installBasesCount }: Iproduct) => {
                    return installBasesCount || 0; // using this rather than data.quantity because quantity is not working
                }
            };
        } else {
            return {
                Header: 'qty',
                id: 'quantity',
                width: 80,
                accessor: ({ workOrders }: IinstallBasePopulated) => {
                    return workOrders.length || 0; // using this rather than data.quantity because quantity is not working
                }
            };
        }
    };

    const getNameColumn = (): Column<any> => {
        if (isManageAssetView) {
            return {
                Header: 'name',
                id: 'name',
                accessor: (inventory: Iproduct) => inventory.name,
                minWidth: 400
            };
        } else {
            return {
                Header: 'name',
                id: 'name',
                accessor: (inventory: IinstallBasePopulated) => {
                    return (
                        <span
                            className={`${
                                inventory.sapEquipmentNumber ? 'blue-bold' : ''
                            }`}
                        >
                            {inventory.productNameString}
                        </span>
                    );
                },
                minWidth: 400
            };
        }
    };

    const columns = () =>
        TableUtil.translateHeaders(
            [
                {
                    id: 'expander-toggle',
                    expander: true,
                    Expander: TableUtil.expanderToggle,
                    style: {
                        cursor: 'pointer',
                        textAlign: 'left',
                        userSelect: 'none'
                    }
                },
                getNameColumn(),
                getQuantityColumn()
            ],
            t
        );

    const mobileColumns = () =>
        TableUtil.translateHeaders(
            [
                {
                    Header: 'name',
                    id: 'name',
                    accessor: inventory => {
                        const name = [];

                        name.push(inventory.name);

                        if (inventory.sapMaterialNumber) {
                            name.push(inventory.sapMaterialNumber);
                        }

                        if (inventory.description) {
                            name.push(inventory.description);
                        }

                        return name.join(': ');
                    }
                },
                getQuantityColumn()
            ],
            t
        );

    //Close all modals on component dismount.
    //Prior to this you could leave the page with the modal open and go to a new page where a
    //disembodied modal might be open.
    React.useEffect(() => {
        return () => {
            dispatch({ type: HIDE_MODAL_SEARCH_NEW_PRODUCTS });
            dispatch({ type: HIDE_MODAL_EDIT_PRODUCT });
        };
    }, []);

    React.useEffect(() => {
        setState(currState => ({
            ...currState,
            filterLength: Object.keys(buildSearchControls().controls).length
        }));
    }, [state.searchFieldConfig]);

    React.useEffect(() => {
        props.getProductInfo();

        if (selectedFacility.id.length) {
            props.initInventory(
                selectedFacility.id,
                (count: number, numPages: number) => {
                    setPagingInfo({ count, numPages });
                    return;
                },
                1
            );
            props.getFacility(selectedFacility.id);
            dispatch(getWorkOrders());
        }

        setState({
            ...state,
            currentTile: constants.getTileByURL(props.location.pathname),
            selectedRow: {}
        });
    }, [selectedFacility.id]);

    /*
     * Handle user clicking on a product row column
     * set the selected product to state and open the modal
     */
    const getTdProps = (
        stateFinal: FinalState,
        rowInfo: RowInfo | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (column.id && column.id === 'actions') {
                        return;
                    }
                    setState({
                        ...state,
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !state.selectedRow[
                                rowInfo.viewIndex || 0
                            ]
                        }
                    });
                    if (!state.selectedRow[rowInfo.viewIndex || 0]) {
                        props.setSelectedProductID(rowInfo.original.id);
                    } else {
                        props.clearSelectedProductID();
                    }
                },
                style: {
                    background: state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[
                              `${state.currentTile.color}Tr` as keyof typeof constants.colors
                          ]
                        : ''
                }
            };
        } else {
            return {};
        }
    };

    const getTdPropsMobile = (
        stateFinal: FinalState,
        rowInfo: RowInfo | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column) {
            // launch manage inventory details component

            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    setState({
                        ...state,
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !state.selectedRow[
                                rowInfo.viewIndex || 0
                            ]
                        }
                    });
                },
                style: {
                    background: state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[
                              `${state.currentTile.color}Tr` as keyof typeof constants.colors
                          ]
                        : ''
                }
            };
        } else {
            return {};
        }
    };

    const onPageChange = (page: number) => {
        const newPage = page + 1;
        props.setTableFilter({ page });
        props.initInventory(
            selectedFacility.id,
            (count: number, numPages: number) => {
                setPagingInfo({ count, numPages });
                return;
            },
            newPage
        );
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    const onSearchValueChanges = (value: any, key: string) => {
        setState({ ...state, selectedRow: {} });
        switch (key) {
            case 'search':
                clearTimeout(setTableFilterTimeout);
                setTableFilterTimeout = setTimeout(() => {
                    props.setTableFilter({ search: value, page: 0 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            case 'mainCategory':
                props.setTableFilter({ mainCategory: value, page: 0 });
                break;
            case 'status':
                props.setTableFilter({ status: value, page: 0 });
                break;
            case 'hideVirtualToggle':
                props.setTableFilter({ hideVirtualToggle: value, page: 0 });
                break;
            default:
                break;
        }
    };

    const onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        props.setTableFilter({ sorted: newSorted });
        setState({ ...state, selectedRow: {} });
    };

    /*
     * Handle Product Select from the search new products modal
     */
    const handleSearchNewProductSelect = (
        product: Iproduct,
        installBatchMode: IsearchNewProductInstallBatchMode
    ) => {
        if (installBatchMode.isBatchMode) {
            toastr.confirm(
                props.t('toastMessage:confirmBulkChangeProduct', {
                    count: installBatchMode.installBaseIDs.length
                }),
                {
                    onOk: () => {
                        dispatch(
                            bulkUpdateInstallProducts(
                                product,
                                installBatchMode.installBaseIDs
                            )
                        );
                    }
                }
            );
            return;
        }
        props.toggleEditInstallModal();
        props.setSearchNewProductsSelectedProduct(product);
    };

    /*
     * Handle Merge Product
     */
    const handleMergeProduct = (product: Iproduct) => {
        toastr.confirm(
            `${props.t('manageProductQueue:mergeConfirmPart_01')} \n \n${
                props.selectedProduct.name
            } \n \n ${props.t(
                'manageProductQueue:mergeConfirmPart_02'
            )} \n \n ${product.name}?`,
            {
                onOk: () => {
                    props
                        .mergeProduct(props.selectedProduct.id, product.id)
                        .then(() => {
                            props.initInventory(
                                props.selectedFacility.id,
                                () => {
                                    return;
                                },
                                1
                            );
                            setState({ ...state, selectedRow: {} });
                        })
                        .catch((error: any) => console.error(error));
                }
            }
        );
    };

    const moveFacility = async (newFacilityId: string) => {
        if (state.installIdsToMove.length > 0) {
            const response = await moveFacilitiesAPI(
                newFacilityId,
                state.installIdsToMove
            );
            if (response) {
                setState({
                    ...state,
                    installIdsToMove: [],
                    showSearchNewFacilityModal: false,
                    selectedRow: {}
                });

                toastr.success(
                    props.t('Success'),
                    props.t('Install Bases successfully merged'),
                    constants.toastrSuccess
                );

                // Refresh the table
                props.initInventory(
                    props.selectedFacility.id,
                    () => {
                        return;
                    },
                    1
                );
            } else {
                toastr.warning(
                    props.t('Error'),
                    props.t('Failed to merge Install Bases'),
                    constants.toastrError
                );
            }
        } else {
            toastr.warning(
                props.t('Error'),
                props.t('No install bases were selected'),
                constants.toastrError
            );
        }
    };

    const hideNewFacilitiesModal = () => {
        setState({ ...state, showSearchNewFacilityModal: false });
    };

    const showNewFacilityModal = (ids: string[]) => {
        setState({
            ...state,
            showSearchNewFacilityModal: true,
            installIdsToMove: ids
        });
    };

    // For mobile, if a row is selected, show the ManageInventoryDetails component
    if (isMobile) {
        const key = Object.keys(state.selectedRow);

        if (key.length > 0) {
            const first = key[0];
            const convertedInt = parseInt(first);
            if (!isNaN(convertedInt)) {
                return (
                    <div
                        className={`manage-inventory`}
                        style={{
                            ['--filter-size' as string]: state.filterLength
                        }}
                    >
                        <Banner
                            title={t(props.bannerTitle)}
                            img={state.currentTile.srcBanner}
                            color={state.currentTile.color}
                        >
                            <SelectFacilityContainer
                                t={props.t}
                                classNameOverride={''}
                            />
                        </Banner>
                        <ManageInventoryDetails
                            selectedProduct={props.tableData[convertedInt]}
                            t={props.t}
                            colorButton={colorButton}
                            facility={props.selectedFacility}
                            openSearchFacilityModal={() => {
                                return;
                            }}
                            goBack={() => {
                                setState({ ...state, selectedRow: {} });
                            }}
                            setSelectedInstallBaseID={
                                props.setSelectedInstallBaseID
                            }
                            setSelectedProductID={props.setSelectedProductID}
                            toggleEditInstallModal={
                                props.toggleEditInstallModal
                            }
                        />

                        <MPResultListMobileModal
                            colorButton={colorButton}
                            secondModal={false}
                            locationString={TableUtil.buildLocation(
                                props.selectedInstallBase,
                                props.selectedFacility
                            )}
                            selectedMeasurementPointResultID={
                                props.selectedInstallBase
                                    .latestMeasurementPointListResultID
                            }
                        />

                        <MPResultHistoryMobileModal
                            colorButton={colorButton}
                            secondModal={false}
                            locationString={TableUtil.buildLocation(
                                props.selectedInstallBase,
                                props.selectedFacility
                            )}
                            selectedInstallBaseID={props.selectedInstallBase.id}
                        />

                        <MPResultListNotesMobileModal
                            colorButton={colorButton}
                            secondModal={true}
                        />

                        <EditInstallModal
                            colorButton={colorButton}
                            selectedProduct={selectedProductForEditInstall}
                        />

                        <EditWorkOrderModal
                            colorButton={colorButton}
                            secondModal={true}
                        />

                        <InstallContactModal colorButton={colorButton} />
                    </div>
                );
            }
        }
    }

    const scrollingHeight = isMobile ? 'calc(100vh - 60px - 84px)' : '100%';
    const overFlow = isMobile ? 'auto' : 'initial';
    return (
        <div
            className={`manage-inventory ${props.className}`}
            style={{
                ['--filter-size' as string]: state.filterLength
            }}
        >
            <Banner
                title={t(props.bannerTitle)}
                img={state.currentTile.srcBanner}
                color={state.currentTile.color}
            >
                <SelectFacilityContainer t={props.t} classNameOverride={''} />
                {props.canRequestQuote && props.match.path !== '/assetStatus' && (
                    <Button
                        className="request-for-quote-cart-button"
                        bsStyle="primary"
                        onClick={() =>
                            props.toggleShoppingCartModal('INVENTORY')
                        }
                    >
                        <FontAwesomeIcon icon="shopping-cart" />
                        <Badge>{props.cartTotal} </Badge>
                    </Button>
                )}
            </Banner>

            <div style={{ height: scrollingHeight, overflowY: overFlow }}>
                <SearchTableForm
                    fieldConfig={state.searchFieldConfig}
                    handleSubmit={props.initInventory}
                    loading={props.loading}
                    colorButton={colorButton}
                    subscribeValueChanges={true}
                    onValueChanges={onSearchValueChanges}
                    t={props.t}
                    showSearchButton={false}
                >
                    {!isMobile && (
                        <>
                            {props.canEditInstalls && isManageAssetView && (
                                <div>
                                    <Button
                                        className="table-import-button"
                                        bsStyle="link"
                                        onClick={props.toggleImportInstallModal}
                                    >
                                        {t('import')}
                                    </Button>

                                    <Button
                                        className="table-add-button"
                                        bsStyle="link"
                                        onClick={() => {
                                            props.clearSelectedProductID();
                                            props.clearSelectedInstallBaseID();
                                            props.toggleSearchNewProductsModal();
                                        }}
                                    >
                                        {t('manageInventory:newProduct')}
                                    </Button>
                                </div>
                            )}
                            {isManageAssetView === false && (
                                <LinkContainer to="/dashboard">
                                    <Button
                                        bsStyle="link"
                                        className="table-add-button"
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />{' '}
                                        {t('back')}
                                    </Button>
                                </LinkContainer>
                            )}
                        </>
                    )}
                </SearchTableForm>

                {/* MOBILE */}
                {isMobile && (
                    <ReactTable
                        data={props.tableData}
                        columns={mobileColumns()}
                        getTdProps={getTdPropsMobile}
                        defaultPageSize={
                            manageInventory.tableFilters.rows ||
                            constants.tablePageSizeDefault
                        }
                        page={manageInventory.tableFilters.page}
                        showPageSizeOptions={true}
                        className={`beacon-table -highlight ${state.currentTile.color}`}
                        previousText={t('common:previous')}
                        nextText={t('common:next')}
                        onPageChange={onPageChange}
                        onSortedChange={onSortedChanged}
                        sortable={false}
                        multiSort={false}
                        noDataText={t('common:noDataText')}
                        resizable={false}
                        loading={props.loading && props.tableData.length === 0}
                    />
                )}
            </div>

            {/* DESKTOP */}
            {!isMobile && (
                <ReactTable
                    data={props.tableData}
                    columns={columns()}
                    getTdProps={getTdProps}
                    defaultPageSize={100}
                    page={manageInventory.tableFilters.page}
                    pages={pagingInfo.numPages}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={onPageChange}
                    onSortedChange={onSortedChanged}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    SubComponent={(rowInfo: RowInfo) => {
                        if (isManageAssetView === false) {
                            return (
                                <WorkOrderExpander {...rowInfo} t={props.t} />
                            );
                        }
                        return (
                            <InstallBasesExpanderContainer
                                {...rowInfo}
                                t={props.t}
                                colorButton={colorButton}
                                openSearchFacilityModal={showNewFacilityModal}
                            />
                        );
                    }}
                    resizable={false}
                    expanded={state.selectedRow}
                    loading={props.loading && props.tableData.length === 0}
                />
            )}

            <EditProductModal
                selectedItem={props.selectedProduct || initialProduct}
                colorButton={colorButton}
                secondWideModal={
                    manageInventory.showSearchNewProductsModal === true &&
                    props.selectedProduct.id.length === 0
                }
                selectedQueueObject={initialProductQueueObject}
            />
            <ShoppingCartModal
                colorButton={colorButton}
                t={props.t}
                cart={manageInventory.cart}
                title={props.t('manageInventory:requestForQuote')}
                checkout={props.requestQuote}
                cartName="INVENTORY"
                ShoppingCartForm={ShoppingCartForm}
                showCost={false}
            />
            <EditInstallModal
                colorButton={colorButton}
                selectedProduct={selectedProductForEditInstall}
            />
            <InstallContactModal colorButton={colorButton} />

            <SearchNewProductsModal
                selectedItem={props.selectedProduct}
                colorButton={colorButton}
                handleProductSelect={handleSearchNewProductSelect}
                handleMergeProduct={handleMergeProduct}
                // If we are in batch mode, then we don't want to enable merge product mode.
                // we also don't want it to show up as a second modal.
                secondModal={
                    props.manageInventory.searchNewProductInstallBatchMode
                        .isBatchMode
                        ? false
                        : props.selectedProduct.id.length !== 0
                }
                enableMergeProductMode={
                    props.manageInventory.searchNewProductInstallBatchMode
                        .isBatchMode
                        ? false
                        : props.selectedProduct.id.length !== 0
                }
                wideModal={true}
                isApproved={props.selectedProduct.id.length !== 0}
                disableCreateNew={false}
            />
            <ImportInstallModal colorButton={colorButton} />
            <MPResultListModal
                colorButton={colorButton}
                secondModal={false}
                locationString={TableUtil.buildLocation(
                    props.selectedInstallBase,
                    props.selectedFacility
                )}
                selectedMeasurementPointResultID={
                    props.selectedInstallBase.latestMeasurementPointListResultID
                }
            />
            <MPResultHistoryModal
                colorButton={colorButton}
                secondModal={false}
                locationString={TableUtil.buildLocation(
                    props.selectedInstallBase,
                    props.selectedFacility
                )}
                selectedInstallBaseID={props.selectedInstallBase.id}
            />
            <EditWorkOrderModal
                colorButton={colorButton}
                secondModal={props.manageInventory.showMPResultHistoryModal}
            />
            <MPResultListNotesModal
                colorButton={colorButton}
                secondModal={true}
            />

            <WorkOrderCloseModal
                colorButton={colorButton}
                selectedInstallBaseID={props.selectedInstallBase.id}
                enableRepairMode={true}
                secondModal={false}
            />

            <SearchNewFacilityModal
                showModal={state.showSearchNewFacilityModal}
                hideModal={hideNewFacilitiesModal}
                colorButton={colorButton}
                moveFacility={moveFacility}
            />
        </div>
    );
};

export default ManageInventory;
